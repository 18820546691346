import React from 'react';
import {withTranslation} from "react-i18next";
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';

import {loadTextsByNamespace} from "../../../../server/helper/ssrHelper";
import config from "../../../config/main.config";
import BreadCrumbs from '../../../components/breadcrumbs';
import EditHeaderComponent from '../../../components/editHeader';
import EditHTMLComponent from '../../../components/editHTML';
import EditButtonComponent from "../../../components/editButton/EditButtonComponent";

const namespace = "Imprint";

const Imprint = () => {

    return (
        <Grid className='background-primary text-primary max-width'>
            <Grid.Row centered={true}>
                <Grid.Column computer='14' tablet='14' mobile='14' className={'display-none'}>
                    <BreadCrumbs/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered={true}>
                <Grid.Column computer='14' tablet='14' mobile='14'>
                    <EditHeaderComponent namespace={namespace} id={`${namespace}-header`} primary={true} type='h1'/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered={true}>
                <Grid.Column computer='14' tablet='14' mobile='14'>
                    <EditHTMLComponent namespace={namespace} id={`${namespace}-text-1`}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

Imprint.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'Imprint').then((text) => {
        return text;
    })
}

export default withTranslation(['common'])(Imprint);
