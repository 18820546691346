import React, {useEffect, useState} from "react"
import {withTranslation} from "react-i18next";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";

import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import {Button} from 'semantic-ui-react/dist/commonjs'
import PropTypes from "prop-types";
import {
    fetchMissingTextById, getLanguage
} from "../../helper/util";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import DropdownItem from "../dropdownMenu/DropdownItem";
import {textUpdate} from "../../helper/TextTranslatorUtil";
import * as utils from "../editHeader/utils";

function handleSave(editedButton, namespace, id, language, textStore, i18n, onClose) {
    let buttonToEdit = {
        content: editedButton.to, title: editedButton.text, description: editedButton.className, isNamespace: namespace
    };
    textUpdate(textStore, buttonToEdit, id, language, namespace).then(bool => {
        if (language === i18n.language) {
            onClose(buttonToEdit);
        } else {
            onClose("");
        }
    });
}

function updateLanguage(textStore, id, language, setButtonPropertiesEdited) {
    if (Object.keys(textStore).length > 0 && typeof textStore[id] !== "undefined" && typeof textStore[id][language] !== "undefined") {
        setButtonPropertiesEdited({
            text: textStore[id][language].title,
            to: textStore[id][language].content,
            className: textStore[id][language].description
        });
    } else {
        setButtonPropertiesEdited({text: '', className: '', to: ''});
    }
}

const EditButtonEditor = (props) => {

    const {id, modalOpen, onClose, namespace, buttonProperties} = props;
    let {i18n} = props;
    const [language, setLanguage] = useState(getLanguage(i18n.language));
    const [targetLanguage, setTargetLanguage] = useState({});
    const [buttonPropertiesEdited, setButtonPropertiesEdited] = useState(buttonProperties);

    const [activeLanguageOptions, setActiveLanguageOptions] = useState([]);
    const [textStore, setTextStore] = useState(props.textStore);

    useEffect(() => {
        utils.initializeTextStore(textStore, id, namespace, setTextStore, setActiveLanguageOptions, setTargetLanguage, i18n);
        fetchMissingTextById(textStore, id, i18n.language).then(text => {
            let newTextStore = {
                ...textStore,
                ...text
            }
            if (text) {
                setTextStore(newTextStore);
                setButtonPropertiesEdited({
                    text: text[id][i18n.language].title,
                    to: text[id][i18n.language].content,
                    className: text[id][i18n.language].description
                });
            }
        });
    }, []);


    useEffect(() => {
        updateLanguage(textStore, id, language.languageCode, setButtonPropertiesEdited);
    }, [language]);

    return (<Modal open={modalOpen}>
        <Modal.Header>Edit Button</Modal.Header>
        <Modal.Content>
            <Form>
                <Form.Field style={{paddingBottom: "10px"}}>
                    {activeLanguageOptions.length > 1 ? <div style={{display: "inline-flex"}}>
                        <label className={"header"}
                               style={{
                                   margin: "auto", marginRight: "5px"
                               }}>
                            <b>{i18n.t("common:common.language")}: </b>
                        </label>
                        <p style={{margin: "auto", marginRight: "5px"}}>{language.language}</p>
                        <DropdownMenu name={"Select Language"}
                                      selector={true}
                                      defaultValue={language.label}
                                      onChange={(selection) => {
                                          setLanguage(getLanguage(activeLanguageOptions.find(langToSet => langToSet.label === selection).value));
                                      }}>
                            {activeLanguageOptions.map((language, index) => (
                                <DropdownItem key={index}>{language.label}</DropdownItem>))}
                        </DropdownMenu>
                    </div> : null}
                </Form.Field>
                <Form.Field>
                    <label>Classname:</label>
                    <input value={buttonPropertiesEdited.className} onChange={(e) => {
                        setButtonPropertiesEdited({...buttonPropertiesEdited, className: e.target.value})
                    }}/>
                </Form.Field>
                <Form.Field>
                    <label>Link to:</label>
                    <input value={buttonPropertiesEdited.to} onChange={(e) => {
                        setButtonPropertiesEdited({...buttonPropertiesEdited, to: e.target.value})
                    }}/>
                </Form.Field>

                <Form.Field>
                    <label>Button Text:</label>
                    <input value={buttonPropertiesEdited.text} onChange={(e) => {
                        setButtonPropertiesEdited({...buttonPropertiesEdited, text: e.target.value})
                    }}/>
                </Form.Field>
<Form.Field>
    <label>Preview:</label>
    <button className={buttonPropertiesEdited.className ? buttonPropertiesEdited.className : ''}>
        {buttonPropertiesEdited.text}
    </button>
</Form.Field>

            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button color={'green'}
                    onClick={_ => handleSave(buttonPropertiesEdited, namespace, id, language.languageCode, textStore, i18n, onClose)}>{i18n.t("common:common.save")}</Button>
            <Button color={'red'} onClick={_ => {
                setButtonPropertiesEdited({text: '', to: '', className: ''})
                onClose("")
            }}>{i18n.t("common:common.cancel")}</Button>
        </Modal.Actions>
    </Modal>)
}


EditButtonEditor.propTypes = {
    id: PropTypes.string, modalOpen: PropTypes.bool, onClose: PropTypes.func, textStore: PropTypes.object,
};

EditButtonEditor.defaultProps = {
    id: Math.random().toString(), modalOpen: false, textStore: {}, onClose: () => {
    }
};

export default withTranslation(['common', 'edit'])(EditButtonEditor)
