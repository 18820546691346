import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";

import ImprintPage from "../userContent/userPages/ImprintPage/ImprintPage";

const Imprint = () => {
    useEffect(_=>{
       if(typeof window !== "undefined"){
           window.scrollTo(0,0)
       }
    },[])


    return (
        <ImprintPage/>
    )
}

export default withTranslation()(Imprint)