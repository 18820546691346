import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Label} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

import {fetchMissingTextById} from "../../helper/util";
import userStore from "../adminComponents/userManagement/userStore";
import {getButtonItemById} from "../../helper/cmsHelper";
import {useLocation} from "react-router-dom";
import {useServerData} from "../../state/serverDataContext";
import history from "../../helper/browserHistory";
import EditButtonEditor from "./EditButtonEditor";
import {isEntryInDB} from "../../../server/helper/ssrHelper";


/*
CMS Properties:
title -> text
description -> className
content -> to

 */

const EditButtonComponent = (props) => {
    const {id, namespace, style} = props;
    let {i18n} = props;
    const [textStore, setTextStore] = useState(useServerData());
    const [modalOpen, setModalOpen] = useState(false);
    const [buttonProperties, setButtonProperties] = useState(getButtonItemById(textStore, id, i18n.language));
    const [canEdit, setCanEdit] = useState(userStore.isAdmin || userStore.isContentCreator);
    const search = useLocation().search;


    useEffect(() => {
        if (typeof textStore[id] === "undefined" && (userStore.isAdmin || userStore.isContentCreator)) {
            isEntryInDB(id, namespace).then(text => {
                if (text) {
                    setTextStore({...textStore, text});
                }
            });
        }
        fetchMissingTextById(textStore, id, i18n.language).then(text => {
            let newTextStore = {
                ...textStore,
                ...text
            }
            if (text) {
                setTextStore(newTextStore);
                setButtonProperties({
                    text: text[id][i18n.language].title,
                    to: text[id][i18n.language].content,
                    className: text[id][i18n.language].description
                });
            }
        });
    }, []);


    useEffect(() => {
        setCanEdit((userStore.isAdmin || userStore.isContentCreator) && search !== "?preview");
    }, [userStore.isAdmin, userStore.isContentCreator, search]);

    if (canEdit && typeof document !== "undefined") {
        return (
            <>
                <Button className={"circular edit_button admin-button-primary"}
                        style={{top: '10px',zIndex:1000}}
                        icon={"edit outline"}
                        onClick={() => {
                            setModalOpen(true);
                        }}/>
                <EditButtonEditor id={id}
                                  modalOpen={modalOpen}
                                  textStore={textStore}
                                  buttonProperties={buttonProperties}
                                  namespace={namespace}
                                  onClose={(text) => {
                                      if (text !== "") {
                                          setButtonProperties({
                                              text: text.title,
                                              to: text.content,
                                              className: text.description
                                          });
                                      }
                                      setModalOpen(false);
                                  }}
                />
                <button className={buttonProperties.className ? buttonProperties.className : ''}
                        style={style ? style : {}}
                        onClick={_ => buttonProperties.to ? history.push('/' + i18n.language + '/' + buttonProperties.to) : null}>
                    {buttonProperties.text}
                </button>
                {
                    buttonProperties.namespace === namespace ? ""
                        : <Label size={"tiny"}
                                 className={"namespace-note"}
                                 style={{opacity: "80%"}}
                                 floating
                                 color={"red"}>
                            !
                        </Label>
                }
            </>
        )
    } else {
        return (
            <button className={buttonProperties.className ? buttonProperties.className : ''}
                    style={style ? style : {}}
                    onClick={_ => buttonProperties.to ? history.push('/' + i18n.language + '/' + buttonProperties.to) : null}>
                {buttonProperties.text}
            </button>
        )
    }
}

EditButtonComponent.propTypes = {
    id: PropTypes.string,
    namespace: PropTypes.string
};

EditButtonComponent.defaultProps = {
    id: Math.random().toString(),
    namespace: "",
};

export default withTranslation(['common'])(EditButtonComponent)
